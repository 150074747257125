/*Vendor z-Indexes
Vendor/Widgets: 1000 - 2000
Modals: 3000 */
iframe#kustomer-ui-sdk-iframe {
  z-index: 1000 !important;
}

div#attentive_overlay {
  z-index: 1500 !important;
}
