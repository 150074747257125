@import './vendor.css';

@config '../tailwind.config.js';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scroll-snap-x-mandatory {
    scroll-snap-type: x mandatory;
  }

  .scroll-snap-y-mandatory {
    scroll-snap-type: y mandatory;
  }

  .scroll-snap-start {
    scroll-snap-align: start;
  }

  .scroll-snap-center {
    scroll-snap-align: center;
  }

  .focus-visible:focus {
    outline: 2px dotted #454d79;
    outline-offset: 2px;
  }

  .pulsate {
    animation: Pulsate 1s linear infinite;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hide-spin::-webkit-inner-spin-button,
  .hide-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .hide-spin[type='number'] {
    -moz-appearance: textfield;
  }

  .col-count-2 {
    column-count: 2;
    break-inside: avoid;
  }

  /* Alternative to display: none (hidden). e.g. use with hidden label meant for use with a screen reader */
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .no-webkit-appearance {
    -webkit-appearance: none;
  }

  .h-full-native {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
  }

  .select-align-right {
    direction: rtl;
  }

  /* clears the 'X' from Internet Explorer */
  input.hide-clear::-ms-clear,
  input.hide-clear::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input.hide-clear::-webkit-search-decoration,
  input.hide-clear::-webkit-search-cancel-button,
  input.hide-clear::-webkit-search-results-button,
  input.hide-clear::-webkit-search-results-decoration {
    display: none;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* Hide select chevron in IE */
select::-ms-expand {
  display: none;
}

/* Hide select chevron elsewhere */
select {
  -webkit-appearance: none;
  appearance: none;
}

@keyframes Pulsate {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

details summary {
  position: relative;
  list-style-type: none; /* Firefox */
}

details summary::-webkit-details-marker {
  display: none; /* Chrome */
}

details.accordion summary::marker {
  display: none;
}

details.accordion summary::after {
  display: inline-block;
  background-image: url('../public/icon/chevron-down-light.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
}

details summary::after {
  content: '';
}

/* nprogress */

#nprogress .spinner {
  display: none !important;
}

#nprogress .peg {
  box-shadow:
    0 0 10px #9e8fff,
    0 0 5px #9e8fff !important;
}

#nprogress .bar {
  background: #9e8fff !important;
  height: 4px !important;
}

.activeSlideDots {
  border: 1px solid #6c479b !important;
  background: #6c479b !important;
}
